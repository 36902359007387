<script>
import PageHeader from "@/components/page-header";
import { BredcrumpManagerEncode } from "@/common/bredcrumb-common"
import { networkRequesErrorResponseLogic } from "@/common/http-common-response-error"
import { ApiClientSelectedPlatform } from "@/common/http-common"
export default {
    components: {
        PageHeader
    },
    data() {
        return {
            title: this.$t('country.create'),
            items: []
        };
    },
    methods: {
        getModules() {
            const self = this;
            document.getElementById("preloader").style.display = "block";
            document.getElementById("status").style.display = "block";
            var rs = JSON.parse(atob(this.$route.query.data))
            ApiClientSelectedPlatform(rs.platformId).get("/v1/admin/countries").then(function (response) {
                self.items = response.data.items
                document.getElementById("preloader").style.display = "none";
                document.getElementById("status").style.display = "none";
                BredcrumpManagerEncode(self, { path: self.$route.path, title: 'country.create' })
            }).catch(
                function (error) {
                    networkRequesErrorResponseLogic(self, error)
                }
            )
        },
        submitForm() {
            const self = this;
            document.getElementById("preloader").style.display = "block";
            document.getElementById("status").style.display = "block";
            var rs = JSON.parse(atob(this.$route.query.data))
            const json = JSON.stringify(self.items);

            ApiClientSelectedPlatform(rs.platformId).put("/v1/admin/countries", json).then(function (response) {
                console.log(response)
                if (self.$route.query.data) {
                    var rs = JSON.parse(atob(self.$route.query.data))
                    if (rs.items) {
                        self.$router.replace({ path: rs.items[rs.items.length - 2].path, query: { data: rs.items[rs.items.length - 2].data } }).catch(() => { });
                    }
                }
            }).catch(
                function (error) {
                    networkRequesErrorResponseLogic(self, error)
                }
            )
        }
    },
    mounted() {
        this.getModules()
    }
};
</script>
<template>
    <div class="row">
        <div class="col-xl-12" style="padding: 0!important;">
            <PageHeader :title="title" />
        </div>
        <div class="col-xl-12">
            <div class="row">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title">{{ this.$t('country.create') }}</h4>
                            <p class="card-title-desc">
                            </p>
                            <div class="row">
                                <div class="col-12">
                                    <form class="form-horizontal" role="form" @submit.prevent="submitForm">
                                        <div class="container">
                                            <div class="row">

                                                <div class="col-md-12" v-for="(item, index) in items" :key="index">
                                                    <div class="row">
                                                        <div class="custom-control custom-checkbox mb-3 col-4">
                                                            <input type="checkbox" class="custom-control-input"
                                                                v-model="item.selected"
                                                                :id="item.id" />
                                                            <label class="custom-control-label"
                                                                style="font-size:0.8rem!important"
                                                                :for="item.id">{{
                                                                    item.title }}</label>
                                                        </div>
                                                    </div>

                                                </div>
                                                <b-button variant="primary" type="submit">{{
                                                    $t('buttons.save') }}</b-button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>